import { useParams, useNavigate } from "react-router-dom";
import useFetch from "./useFetch";
import { useState } from "react";
import parser from "html-react-parser";
import PendingBlog from "./items/PendingBlog";
import { remove, ref, getDatabase } from "firebase/database";
import app from "./firebase/FirebaseConfig";
import { Helmet } from "react-helmet";
import config from "./firebase/Config";

const BlogDetails = ({ posttype, isLogged }) => {
  const { id } = useParams();
  const [delError, setDelError] = useState(false);

  const {
    data: blog,
    error,
    isPending,
  } = useFetch(`${config.db_path}/content/${id}`);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const db = getDatabase(app);
    const dbRef = ref(db, `${config.db_path}/${blog.type}s/${blog.id}`);
    const contentRef = ref(db, `${config.db_path}/content/${blog.id}`);
    await remove(dbRef);
    await remove(contentRef)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error setting data in Firebase:", error);
        setDelError(error.message);
      });
  };

  const handleEdit = () => {
    navigate(`/edit/${blog.type}/${blog.id}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GEOECO | {blog ? blog.title : ""}</title>
        <meta name="description" content={blog ? blog.description : ""} />
        <meta
          name="keywords"
          content="environmental projects, renewable energy projects, environmental compliance, sustainable solutions, GIS mapping projects, Sri Lanka, Port City"
        />
        <meta name="author" content="GEOECO" />
        <meta
          property="og:title"
          content={`GEOECO ${posttype} | ${blog ? blog.title : ""}`}
        />
        <meta
          property="og:description"
          content={blog ? blog.description : ""}
        />
        <meta
          property="og:url"
          content={`https://geoeco.org/${posttype}s/${id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={blog ? blog.cover : ""} />
      </Helmet>
      <div className="z-20 mx-auto max-w-6xl px-4">
        <div className="w-full backdrop-blur-sm">
          {error && <div>{error}</div>}
          {delError && <div>{delError}</div>}
          {isPending && <PendingBlog />}

          {blog && (
            <div>
              <div className="relative my-5 overflow-hidden rounded-lg border-opacity-90">
                <img
                  className="absolute bottom-0"
                  src="https://4kwallpapers.com/images/wallpapers/gradient-background-3840x2160-10974.jpg"
                />
                <div className="mx-auto w-full bg-slate-200 bg-opacity-10 p-4 text-center shadow-lg backdrop-blur-xl sm:p-6">
                  <h1 className="poppins-bold text-white">{blog.title}</h1>
                  <p className="poppins-light mt-1 text-white">
                    Written by {blog.author}
                  </p>
                </div>
              </div>

              <article className="mx-auto my-5 w-full rounded-lg border border-white border-opacity-30 bg-slate-50 bg-opacity-40 p-4 shadow-lg backdrop-blur-lg sm:p-6">
                <div className="tiptap !m-0 border border-none !p-0">
                  {parser(blog.body)}
                </div>
                {isLogged && (
                  <div className="mt-10">
                    <button
                      onClick={handleDelete}
                      className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
                    >
                      Delete {posttype}
                    </button>
                    <button
                      onClick={handleEdit}
                      className="mx-2 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                    >
                      Edit {posttype}
                    </button>
                  </div>
                )}
              </article>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
