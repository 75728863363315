// import "./App.css";
import BlogDetails from "./components/BlogDetails";
import Create from "./components/Create";
import NavBar from "./components/items/NavBar";
import Blogs from "./components/Blogs";
import Edit from "./components/Edit";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import useCheckLogin from "./components/firebase/useCheckLogin";
import Projects from "./components/Projects";
import Footer from "./components/items/Footer";
import { Helmet } from "react-helmet";
// import Custom from "./components/Custom";
import seoImg from "./img/seo.png";

export default function App() {
  const { isLogged, setIsLogged } = useCheckLogin();

  return (
    <Router>
      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            GEOECO | Leading Environmental Consulting Services from Sri Lanka
          </title>
          <meta
            name="description"
            content="GEOECO provides a diverse array of environmental services including soil mapping, crop recommendations, environmental compliance audits, and renewable energy project management. Visit geoeco.org to learn more."
          />
          <meta
            name="keywords"
            content="environmental consulting, soil mapping, crop recommendation, agricultural training, environmental impact assessments, renewable energy, waste management, GIS mapping"
          />
          <meta name="author" content="GEOECO" />
          <meta
            property="og:title"
            content="GEOECO - Environmental Consulting Services"
          />
          <meta
            property="og:description"
            content="Offering a diverse skill set from our environmental consultants and a complete array of services covering air, land, and water."
          />
          <meta property="og:url" content="https://geoeco.org" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={seoImg} />
        </Helmet>
        <NavBar isLogged={isLogged} />
        <main className="mx-auto flex min-h-screen flex-col">
          <div className="">
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route
                exact
                path="/blogs"
                element={<Blogs isLogged={isLogged} />}
              ></Route>
              <Route
                exact
                path="/projects"
                element={<Projects isLogged={isLogged} />}
              ></Route>
              {/* <Route exact path="/test" element={<Custom />}></Route> */}
              <Route
                path="/login"
                element={
                  <Login isLogged={isLogged} setIsLogged={setIsLogged} />
                }
              ></Route>
              <Route
                path="/blogs/:id"
                element={<BlogDetails posttype={"blog"} isLogged={isLogged} />}
              ></Route>
              <Route
                path="/projects/:id"
                element={
                  <BlogDetails posttype={"project"} isLogged={isLogged} />
                }
              ></Route>
              {isLogged && (
                <>
                  <Route exact path="/create" element={<Create />}></Route>
                  <Route
                    path="/edit/blog/:id"
                    element={<Edit posttype={"blog"} isLogged={isLogged} />}
                  ></Route>
                  <Route
                    path="/edit/project/:id"
                    element={<Edit posttype={"project"} isLogged={isLogged} />}
                  ></Route>
                </>
              )}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer className="mt-auto" />
        </main>
      </div>
    </Router>
  );
}
