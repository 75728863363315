import TipTap from "./TipTap";

const MyEditor = ({
  setTitle,
  title,
  setAuthor,
  author,
  setBody,
  body,
  coverImg,
  setCoverImg,
  isPending,
  handleSubmit,
  setAsProject,
  asProject,
  editorState,
  date,
  setDate,
  description,
  setDescription,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mx-4 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Blog Title
          </label>
          <div className="mt-1">
            <input
              required
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Cover Image URL
          </label>
          <div className="mt-1">
            <input
              required
              onChange={(e) => setCoverImg(e.target.value)}
              value={coverImg}
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Description
          </label>
          <div className="mt-1">
            <input
              required
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Date
          </label>
          <div className="mt-1">
            <input
              required
              onChange={(e) => setDate(e.target.value)}
              value={date}
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Blog Body
          </label>
          <div className="mt-1">
            <TipTap setBody={setBody} body={body} />
          </div>
          <p className="mt-3 text-sm leading-6 text-gray-600">
            Write a few sentences.
          </p>
        </div>

        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Username
          </label>
          <select
            required
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="Mr. Aruna Wimalathilake">
              Mr. Aruna Wimalathilake
            </option>
            <option value="Mr. Aruna Sujith">Mr. Aruna Sujith</option>
            <option value="Mr. A.S.Wimalathilake">Mr. A.S.Wimalathilake</option>
          </select>
        </div>

        <div className="col-span-full">
          <label className="inline-flex cursor-pointer items-center">
            <input
              type="checkbox"
              checked={asProject}
              className="peer sr-only"
              onChange={(e) => setAsProject(e.target.checked)}
            />
            <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
              Save As {asProject ? "Project" : "Blog"}
            </span>
          </label>
        </div>
        <div className="col-span-full">
          {!isPending && (
            <button
              id="MyEditorSubmitBtn"
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {editorState} {asProject ? "Project" : "Blog"}
            </button>
          )}
          {isPending && (
            <button
              disabled
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {editorState}ing {asProject ? "Project" : "Blog"}...
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default MyEditor;
