import { useState, useEffect } from "react";
import app from "./firebase/FirebaseConfig";
import { getDatabase, ref, get } from "firebase/database";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(false);

  const handleFetch = async () => {
    setIsPending(true);
    const db = getDatabase(app);
    const dbRef = ref(db, url);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const jsonVal = snapshot.toJSON();
      setData(jsonVal);
      setIsPending(false);
    } else {
      setIsPending(false);
      setError("Could not fetch data from that resource");
    }
  };

  useEffect(() => {
    handleFetch();
  }, [url]);

  return { data, isPending, error };
};

export default useFetch;
