// import mainLogo from "../../img/img3.jpg";

const Footer = () => {
  return (
    <>
      <footer class="mt-auto w-full bg-slate-800 p-4 text-white">
        <div class="container mx-auto flex flex-col items-center justify-between md:flex-row">
          <div class="mb-4 flex items-center md:mb-0">
            <span class="poppins-extrabold text-xl">GEOECO.ORG</span>
          </div>
          <div class="poppins-regular mb-4 text-center md:mb-0">
            <p>© 2024 GEOECO (PVT) LTD. All Rights Reserved.</p>
            <a
              target="_"
              href="https://upekshaip.com"
              class="poppins-regular text-sm text-white"
            >
              Developed by upekshaip
            </a>
          </div>
          <div class="flex items-center justify-center">
            <a href="https://upekshaip.com" target="_" class="ml-2">
              <img
                src="https://upekshaip.github.io/images/upekshaip-white.png"
                alt="upekshaip logo"
                class="w-16"
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
