import { Link } from "react-router-dom";

const BlogList = ({ blogs, title, posttype }) => {
  return (
    <>
      <div className="py-8">
        <h1 className="poppins-extrabold text-center">{title}</h1>
        {blogs.map((blog) => (
          <Link to={`/${posttype}s/${blog.id}`}>
            <div
              className="group mt-2 flex flex-col rounded-md border border-slate-50 bg-slate-100 bg-opacity-20 px-3 py-3 shadow backdrop-blur duration-300 hover:shadow-lg md:flex-row"
              key={blog.id}
            >
              <div className="md:max-w-60">
                <img src={blog.cover} />
              </div>
              <div className="my-3 flex flex-col justify-between px-4 align-middle md:my-0">
                <h4 className="poppins-bold text-green-900 group-hover:text-green-600">
                  {blog.title}{" "}
                </h4>
                <p className="poppins-regular py-2 text-sm">
                  {blog.description}
                </p>
                <div>
                  <p className="poppins-regular text-sm">
                    Written By {blog.author}
                  </p>
                  <p className="poppins-regular text-sm">{blog.date}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default BlogList;
