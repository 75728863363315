import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Underline from "@tiptap/extension-underline";
import {
  EditorProvider,
  useCurrentEditor,
  useEditor,
  EditorContent,
  FloatingMenu,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Youtube from "@tiptap/extension-youtube";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import React, { useCallback } from "react";
import "../../styles/App.css";

const MenuBar = ({ editor }) => {
  const addYoutubeVideo = () => {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: "100%",
      });
    }
  };

  const addImage = useCallback(() => {
    const url = window.prompt("URL");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    if (url === null) {
      return;
    }

    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group prose w-full">
      <p className="block text-sm font-medium leading-6 text-gray-400">
        Basic Options
      </p>
      <div className="button-group-textarea prose w-full">
        <input
          className="color-selector"
          type="color"
          onInput={(event) =>
            editor.chain().focus().setColor(event.target.value).run()
          }
          value={editor.getAttributes("textStyle").color}
          data-testid="setColor"
        />
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive("underline") ? "is-active" : ""}
        >
          Underline
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          Strike
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive("code") ? "is-active" : ""}
        >
          Code
        </button>
        <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          Clear marks
        </button>
        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          Clear nodes
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          Paragraph
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          H1
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          H2
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          H3
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          H4
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive("heading", { level: 5 }) ? "is-active" : ""
          }
        >
          H5
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive("heading", { level: 6 }) ? "is-active" : ""
          }
        >
          H6
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          Bullet list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          Ordered list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
        >
          Code block
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          Blockquote
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          Horizontal rule
        </button>
        <button onClick={() => editor.chain().focus().setHardBreak().run()}>
          Hard break
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          Undo
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          Redo
        </button>
        <button
          onClick={() => editor.chain().focus().setColor("#958DF1").run()}
          className={
            editor.isActive("textStyle", { color: "#958DF1" })
              ? "is-active"
              : ""
          }
        >
          Purple
        </button>
        <button
          onClick={() => editor.chain().focus().setColor("#FF0000").run()}
          className={
            editor.isActive("textStyle", { color: "#FF0000" })
              ? "is-active"
              : ""
          }
        >
          Red
        </button>
      </div>
      <div className="button-group-textarea prose mt-3 w-full">
        <p className="block text-sm font-medium leading-6 text-gray-400">
          Add Links
        </p>

        <button id="add" onClick={addYoutubeVideo}>
          Add YouTube video
        </button>
        <button onClick={addImage}>Set image</button>
        <button
          onClick={setLink}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          Set link
        </button>
        <button
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive("link")}
        >
          Unset link
        </button>
      </div>
      <div className="button-group-textarea prose mt-3 w-full">
        <p className="block text-sm font-medium leading-6 text-gray-400">
          Text Styles
        </p>

        <button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
        >
          Left
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
        >
          Center
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        >
          Right
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          className={
            editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
          }
        >
          Justify
        </button>
        <button onClick={() => editor.chain().focus().unsetTextAlign().run()}>
          Unset text align
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          className={editor.isActive("highlight") ? "is-active" : ""}
        >
          Toggle highlight
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#ffc078" }).run()
          }
          className={
            editor.isActive("highlight", { color: "#ffc078" })
              ? "is-active"
              : ""
          }
        >
          Orange
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#8ce99a" }).run()
          }
          className={
            editor.isActive("highlight", { color: "#8ce99a" })
              ? "is-active"
              : ""
          }
        >
          Green
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#74c0fc" }).run()
          }
          className={
            editor.isActive("highlight", { color: "#74c0fc" })
              ? "is-active"
              : ""
          }
        >
          Blue
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#b197fc" }).run()
          }
          className={
            editor.isActive("highlight", { color: "#b197fc" })
              ? "is-active"
              : ""
          }
        >
          Purple
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "red" }).run()
          }
          className={
            editor.isActive("highlight", { color: "red" }) ? "is-active" : ""
          }
        >
          Red ('red')
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#ffa8a8" }).run()
          }
          className={
            editor.isActive("highlight", { color: "#ffa8a8" })
              ? "is-active"
              : ""
          }
        >
          Red (#ffa8a8)
        </button>
        <button
          onClick={() => editor.chain().focus().unsetHighlight().run()}
          disabled={!editor.isActive("highlight")}
        >
          Unset highlight
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={editor.isActive("subscript") ? "is-active" : ""}
        >
          Toggle subscript
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          className={editor.isActive("superscript") ? "is-active" : ""}
        >
          Toggle superscript
        </button>
      </div>
      <div className="button-group-textarea prose mt-3 w-full">
        <p className="block text-sm font-medium leading-6 text-gray-400">
          Table Options
        </p>
        <button
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
        >
          Insert table
        </button>
        <button onClick={() => editor.chain().focus().addColumnBefore().run()}>
          Add column before
        </button>
        <button onClick={() => editor.chain().focus().addColumnAfter().run()}>
          Add column after
        </button>
        <button onClick={() => editor.chain().focus().deleteColumn().run()}>
          Delete column
        </button>
        <button onClick={() => editor.chain().focus().addRowBefore().run()}>
          Add row before
        </button>
        <button onClick={() => editor.chain().focus().addRowAfter().run()}>
          Add row after
        </button>
        <button onClick={() => editor.chain().focus().deleteRow().run()}>
          Delete row
        </button>
        <button onClick={() => editor.chain().focus().deleteTable().run()}>
          Delete table
        </button>
        <button onClick={() => editor.chain().focus().mergeCells().run()}>
          Merge cells
        </button>
        <button onClick={() => editor.chain().focus().splitCell().run()}>
          Split cell
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
        >
          Toggle header column
        </button>
        <button onClick={() => editor.chain().focus().toggleHeaderRow().run()}>
          Toggle header row
        </button>
        <button onClick={() => editor.chain().focus().toggleHeaderCell().run()}>
          Toggle header cell
        </button>
        <button onClick={() => editor.chain().focus().mergeOrSplit().run()}>
          Merge or split
        </button>
        <button
          onClick={() =>
            editor.chain().focus().setCellAttribute("colspan", 2).run()
          }
        >
          Set cell attribute
        </button>
        <button onClick={() => editor.chain().focus().fixTables().run()}>
          Fix tables
        </button>
        <button onClick={() => editor.chain().focus().goToNextCell().run()}>
          Go to next cell
        </button>
        <button onClick={() => editor.chain().focus().goToPreviousCell().run()}>
          Go to previous cell
        </button>
      </div>
    </div>
  );
};

const TipTap = ({ setBody, body }) => {
  const extensions = [
    TextStyle.configure({ types: [ListItem.name] }),
    Color.configure({
      types: [TextStyle.name, ListItem.name],
    }),
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    Image,
    Underline,
    Superscript,
    Subscript,
    Highlight.configure({ multicolor: true }),
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
    }),
    Youtube.configure({
      controls: false,
      nocookie: true,
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
  ];

  const content = body;

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      //   const json = editor.getJSON();
      setBody(html);
    },
    extensions: extensions,
    content: content,
  });

  return (
    <>
      <MenuBar editor={editor} />
      <EditorContent className="" editor={editor} />
    </>
  );
};

export default TipTap;
