import { useState, useEffect } from "react";
import { auth } from "./FirebaseConfig";

const useCheckLogin = () => {
  const [isLogged, setIsLogged] = useState(false);

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    });
  };

  useEffect(() => {
    fetchUserData();
  });

  return { isLogged, setIsLogged };
};

export default useCheckLogin;
