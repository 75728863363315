import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

export default function NavBar({ isLogged }) {
  const [menuExpand, setMenuExpand] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = (e, id) => {
    navigate("/");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleClose(e);
    }
  };

  const handleExpand = (e) => {
    // console.log(id);
    if (menuExpand) {
      setMenuExpand(false);
    } else {
      setMenuExpand(true);
    }
  };

  const handleClose = (e) => {
    setMenuExpand(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuExpand(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="sticky top-0 z-10 mx-auto flex w-full bg-slate-50 bg-opacity-60 backdrop-blur">
      <nav
        ref={menuRef}
        className={`${menuExpand ? "mb-60" : "mb-0"} my-transition flex w-full transform flex-col justify-between px-5 py-3 align-middle transition duration-500 ease-out md:mb-auto md:flex-row`}
      >
        <Link to="/" onClick={(e) => handleScroll(e, "home")}>
          <h5 className="poppins-extrabold py-1 transition-colors hover:text-green-500">
            GEO ECO
          </h5>
        </Link>
        <ul
          className={`${menuExpand ? "top-10" : "-top-40"} my-transition poppins-light absolute mx-auto flex w-[90vw] flex-col justify-center align-middle md:relative md:top-auto md:w-auto md:flex-row md:gap-7 md:rounded-full md:border-2 md:border-purple-400 md:border-opacity-20 md:px-6 md:shadow-md`}
        >
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "home")}
            className="py-1 text-center transition-colors hover:text-green-500"
          >
            Home
          </Link>
          <button
            onClick={(e) => handleScroll(e, "about")}
            className="py-1 text-center transition-colors hover:text-green-500"
          >
            About
          </button>
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "services")}
            className="py-1 text-center transition-colors hover:text-green-500"
          >
            Services
          </Link>
          <Link
            to="/"
            onClick={(e) => handleScroll(e, "contact")}
            className="py-1 text-center transition-colors hover:text-green-500"
          >
            Contact
          </Link>
        </ul>
        <ul
          className={`${menuExpand ? "top-40" : "-top-36"} poppins-light my-transition absolute mt-4 flex w-[90vw] flex-col gap-2 align-middle md:relative md:top-auto md:mt-0 md:w-auto md:flex-row md:gap-3 md:pt-0`}
        >
          <Link
            to="/projects"
            onClick={handleClose}
            className="z-20 rounded-full bg-green-500 px-4 py-1.5 text-center text-white duration-300 hover:bg-green-800 md:pt-1.5 md:hover:scale-105"
          >
            Projects
          </Link>
          <Link
            to="/blogs"
            onClick={handleClose}
            className="z-20 rounded-full bg-green-500 px-4 py-1.5 text-center text-white duration-300 hover:bg-green-800 md:pt-1.5 md:hover:scale-105"
          >
            Blogs
          </Link>
          {isLogged && (
            <>
              <Link
                to="/login"
                onClick={handleClose}
                className="rounded-full bg-red-500 px-4 py-1.5 text-center text-white duration-300 hover:bg-red-800 md:pt-1.5 md:hover:scale-105"
              >
                Log Out
              </Link>
            </>
          )}
        </ul>
        <button
          onClick={handleExpand}
          className="fixed right-4 top-0 py-5 pl-16 pr-8 md:hidden"
        >
          {menuExpand ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </nav>
    </div>
  );
}
