const PendingBlog = () => {
  return (
    <div className="mx-auto mt-3 w-full rounded-md border-white border-opacity-30 bg-slate-50 bg-opacity-40 p-3 shadow">
      <div className="animate-pulse space-x-4 align-middle">
        <div className="flex-1 space-y-2 py-1">
          <div className="h-2 rounded bg-slate-300 p-4"></div>
          <div className="h-2 rounded bg-slate-300 p-2"></div>
          <div className="!my-4 !mb-10 w-full border"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
            </div>
            <div className="h-2 rounded bg-slate-300"></div>
            <div className="h-2 rounded bg-slate-300"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingBlog;
