import BlogList from "./items/BlogList";
import useFetch from "./useFetch";
import PendingBlog from "./items/PendingBlog";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import seoImg from "../img/seo.png";
import config from "./firebase/Config";

const Blogs = ({ isLogged }) => {
  const { data: blogs, isPending, error } = useFetch(`${config.db_path}/blogs`);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GEOECO Blog | Insights on Environmental Services</title>
        <meta
          name="description"
          content="Read the latest insights and updates from GEOECO's environmental consultants. Our blog covers topics on soil mapping, renewable energy, waste management, and more."
        />
        <meta
          name="keywords"
          content="environmental blog, soil mapping, renewable energy, waste management, environmental impact, GIS mapping"
        />
        <meta name="author" content="GEOECO" />
        <meta
          property="og:title"
          content="GEOECO Blog - Insights on Environmental Services"
        />
        <meta
          property="og:description"
          content="Explore our blog for the latest insights and updates on environmental services from GEOECO's experts."
        />
        <meta property="og:url" content="https://geoeco.org/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={seoImg} />
      </Helmet>

      <div className="relative mx-auto max-w-6xl px-4">
        {isLogged && (
          <Link
            to="/create"
            className="absolute right-0 top-3 rounded-full bg-blue-500 px-4 py-1.5 text-center text-white duration-300 hover:bg-blue-800 md:pt-1.5 md:hover:scale-105"
          >
            Create
          </Link>
        )}
        {isPending && <PendingBlog />}
        {error && <div>Cannot Fetch data from the resources</div>}
        {/* {error && <div>{error}</div>} */}
        {blogs && (
          <BlogList
            blogs={Object.values(blogs)}
            title={"Blog"}
            posttype={"blog"}
          />
        )}
      </div>
    </>
  );
};

export default Blogs;
