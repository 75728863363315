import { Link, useNavigate } from "react-router-dom";
import "../styles/body.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWater,
  faSeedling,
  faDumpsterFire,
  faMapLocationDot,
  faPhone,
  faEnvelopeCircleCheck,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// imgs
import heroImg from "../img/heroImg.png";
import provideImg from "../img/provideImg.jpg";
import about1Img from "../img/about1Img.png";
import about2Img from "../img/about2Img.png";
import about3Img from "../img/about3Img.png";
import serviceImg from "../img/serviceImg.png";

// imgs

const Home = () => {
  const navigate = useNavigate();
  const handleScroll = (id) => {
    navigate("/");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* Hero */}
      <section className="mx-6 my-6" id="home">
        <div className="def-container">
          <div className="def-left">
            <div className="felx w-full flex-col justify-center p-4 text-center align-middle md:text-left">
              <h1 className="poppins-extrabold text-4xl text-green-600 md:text-6xl xl:text-7xl">
                GEO ECO
              </h1>
              <p className="md:text-md poppins-bold mb-6 text-sm">
                Leading Environmental Consultants from Sri Lanka
              </p>
              <Link
                className="rounded-full bg-green-500 px-12 py-2 text-center text-white duration-300 hover:bg-green-800 md:pt-1.5 md:hover:scale-105"
                onClick={() => handleScroll("about")}
              >
                About Us
              </Link>
            </div>
          </div>
          <div className="def-right">
            <img src={heroImg} className="mx-auto max-h-[80vh]" />
          </div>
        </div>
      </section>

      {/* What we provide */}
      <section className="relative mx-6 my-6 overflow-hidden rounded-md bg-white bg-opacity-60 px-8 py-8 shadow-lg md:px-6">
        <div className="absolute bottom-0 left-0 -z-10 h-full w-full bg-white"></div>
        <img className="absolute bottom-0 left-0 -z-10" src={provideImg} />
        <div className="z-20 text-center">
          <h2 className="poppins-extrabold text-4xl text-green-600">
            What We Provide
          </h2>
        </div>
        <div className="mt-6 flex flex-col justify-around align-middle md:flex-row md:gap-4 md:p-3 lg:gap-12">
          <div className="card relative">
            <FontAwesomeIcon
              icon={faWater}
              color="rgb(103,161,13)"
              size="2xl"
            />
            <h3 className="poppins-bold text-lime-600">Hydrological Studies</h3>
            <p className="poppins-medium">
              GEECC's hydrological services provide comprehensive water resource
              assessments and flood risk analyses to ensure sustainable water
              management and infrastructure planning.
            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon
              icon={faSeedling}
              color="rgb(103,161,13)"
              size="2xl"
            />
            <h3 className="poppins-bold text-lime-600">
              Strategic Environmental Planning
            </h3>
            <p className="poppins-medium">
              We offer strategic environmental planning to help clients navigate
              compliance and achieve sustainability goals, aligning projects
              with environmental regulations and best practices.
            </p>
          </div>
          <div className="card">
            <FontAwesomeIcon
              icon={faDumpsterFire}
              color="rgb(103,161,13)"
              size="2xl"
            />

            <h3 className="poppins-bold text-lime-600">Waste Management</h3>
            <p className="poppins-medium">
              Our waste management solutions focus on innovative practices to
              minimize waste, enhance recycling, and ensure safe disposal,
              contributing to a cleaner and healthier environment.
            </p>
          </div>
        </div>
      </section>
      {/* About */}
      <section
        className="relative mx-6 my-6 overflow-hidden bg-opacity-60 py-8"
        id="about"
      >
        <div className="poppins-regular">
          <h2 className="poppins-extrabold my-3 text-center text-4xl text-green-600">
            About Us
          </h2>
          <p className="mb-6 rounded-md bg-white bg-opacity-50 p-8 shadow-lg">
            Founded in 2010, GEO ECO Environmental Consultancy Company (GEECC)
            has rapidly emerged as a leading player in the environmental
            management consulting industry in Sri Lanka. With a strong
            foundation in environmental management and sustainable development,
            GEECC is now expanding its expertise beyond national borders. In the
            21st century, navigating environmental legislation is one of the
            greatest challenges facing Sri Lankan industries and developers. Our
            mission is to guide our clients through this complex landscape with
            precision and insight.
          </p>
          <div className="flex flex-col md:flex-row">
            <div className="my-4 flex flex-col justify-center px-2 align-middle md:my-0 md:w-1/2">
              <img className="mx-4" src={about1Img} />
            </div>
            <div className="flex flex-col justify-center align-middle md:w-1/2">
              <p className="mb-6 ml-4 rounded-md bg-white bg-opacity-50 p-8 shadow-lg">
                As a privately held company, GEECC operates independently,
                ensuring that our services and products are based on truthful,
                judicious technical information delivered confidentially at the
                lowest possible fee. We pride ourselves on our integrity and
                commitment to providing accurate and timely information.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row">
            <div className="flex flex-col justify-center align-middle md:w-1/2">
              <p className="mb-6 mr-4 rounded-md bg-white bg-opacity-50 p-8 shadow-lg">
                Our team comprises specialist consultants from diverse
                environmental fields, supported by a range of local partners.
                This collaborative approach offers our clients unparalleled
                knowledge and expertise in environmental management. Within a
                short period, GEECC has established itself as the foremost
                environmental service provider, known for completing tasks
                successfully and efficiently.
              </p>
            </div>
            <div className="my-4 flex flex-col justify-center px-2 align-middle md:my-0 md:w-1/2">
              <img className="mx-4" src={about2Img} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="my-4 flex flex-col justify-center px-2 align-middle md:my-0 md:w-1/2">
              <img className="px-10 hue-rotate-15" src={about3Img} />
            </div>
            <div className="flex flex-col justify-center align-middle md:w-1/2">
              <p className="mb-6 ml-4 rounded-md bg-white bg-opacity-50 p-8 shadow-lg">
                Our operations are guided by strict codes of ethics,
                professional practices, and philosophies that drive us toward
                achieving our vision and mission. At GEECC, we are dedicated to
                delivering exceptional environmental consulting services,
                helping our clients achieve their sustainability goals with
                confidence and excellence.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Services */}
      <section
        className="relative mx-6 my-6 overflow-hidden rounded-md bg-white bg-opacity-60 px-8 py-8 shadow-lg md:px-6"
        id="services"
      >
        <div className="absolute bottom-0 left-0 -z-10 h-full w-full backdrop-blur"></div>
        <div className="absolute bottom-0 left-0 -z-20 h-full w-full bg-white"></div>
        <img
          src={serviceImg}
          className="absolute bottom-0 left-0 -z-20 hue-rotate-30"
        />
        <div className="z-20 text-center">
          <h2 className="poppins-extrabold text-4xl text-green-600">
            Our Services
          </h2>
          <p className="poppins-regular text-slate-700">
            Offering our clients a diverse skill set from our environmental
            consultants and a complete array of environmental services covering
            air, land, and water:
          </p>
        </div>
        <div className="mt-4 flex w-full justify-center p-6 align-middle">
          <div className="def-service poppins-medium relative flex flex-col justify-around align-middle md:flex-row">
            <ul className="mx-3 md:w-1/2">
              <li>Soil Mapping</li>
              <li>Soil Survey</li>
              <li>Crop Recommendation</li>
              <li>Agricultural Training Programme</li>
              <li>Cost Benefit Analysis (CBA)</li>
              <li>Noise Controlling and Sound Proofing Measures</li>
              <li>Stack Emission Control Measures and Assessment</li>
              <li>Hotel and Industrial Management</li>
              <li>Environmental Compliance Audits</li>
            </ul>
            <ul className="mx-3 md:w-1/2">
              <li>Environmental Impact Assessments (EIA)</li>
              <li>Strategic Environmental Assessments (SEA)</li>
              <li>
                Renewable Energy Project Management (Hydro, Wind, Solar, and
                Biomass)
              </li>
              <li>Hydrology Reports</li>
              <li>Waste Management and Assessment</li>
              <li>Treatment Plants and Controlling Measures</li>
              <li>Hazard and Risk Assessment</li>
              <li>Crack Survey</li>
              <li>GIS and RS Mapping</li>
            </ul>
          </div>
        </div>
        <p className="poppins-regular md:px-5">
          We also provide expertise in earth sciences and in the engineering
          design, procurement, construction management, and operation of
          environmental remediation and treatment systems. Worley Parsons has
          earned the trust of our customers, providing sustainable environmental
          solutions that reduce risk and integrate industry, the environment,
          and the community.
        </p>
      </section>
      <section
        id="contact"
        className="relative mx-6 my-6 overflow-hidden rounded-md"
      >
        <div className="flex flex-col-reverse justify-between align-middle md:flex-row">
          <div className="poppins-regular flex flex-col justify-between align-middle md:w-1/2 md:p-4">
            <div className="mt-3 flex rounded-md bg-white bg-opacity-40 p-8 align-middle md:mt-0">
              <FontAwesomeIcon size="2xl" icon={faMapLocationDot} />
              <p className="ml-3 mt-1.5">
                <b>Address:</b> No.585, Kandy Road, Nittambuwa, Sri Lanka.
              </p>
            </div>
            <div className="mt-3 flex rounded-md bg-white bg-opacity-40 p-8 align-middle md:mt-0">
              <FontAwesomeIcon size="2xl" icon={faPhone} />
              <p className="ml-3 mt-1.5">
                <b>Phone:</b> +94 714831730 / +94 334551484 / +94 776970029
              </p>
            </div>
            <div className="mt-3 flex rounded-md bg-white bg-opacity-40 p-8 align-middle md:mt-0">
              <FontAwesomeIcon size="2xl" icon={faEnvelopeCircleCheck} />
              <p className="ml-3 mt-1.5">
                <b>Email:</b> arunasujith@gmail.com
              </p>
            </div>
            <div className="mt-3 flex rounded-md bg-white bg-opacity-40 p-8 align-middle md:mt-0">
              <FontAwesomeIcon size="2xl" icon={faWhatsapp} />
              <p className="ml-3 mt-1.5">
                <b>WhatsApp:</b> +94 714831730 / +94 776970029
              </p>
            </div>
            <div className="mt-3 flex rounded-md bg-white bg-opacity-40 p-8 align-middle md:mt-0">
              <FontAwesomeIcon size="2xl" icon={faGlobe} />
              <p className="ml-3 mt-1.5">
                <b>Website:</b> geoeco.org
              </p>
            </div>
          </div>
          <div className="md:w-1/2 md:p-4">
            <div className="rounded-md bg-white bg-opacity-70 p-8 shadow-md">
              <h2 className="poppins-extrabold text-center text-4xl text-green-600">
                Contact Us
              </h2>
              <form
                method="POST"
                action=""
                className="poppins-regular flex flex-col justify-evenly align-middle"
              >
                <label className="formLabel" for="email__name">
                  Name
                </label>
                <input
                  className="formInput"
                  id="email__name"
                  type="text"
                  name="name"
                  required
                />

                <label className="formLabel" for="email__email">
                  Email
                </label>
                <input
                  className="formInput"
                  id="email__email"
                  type="email"
                  name="email"
                  required
                />

                <label className="formLabel" for="email__phone">
                  Phone
                </label>
                <input
                  className="formInput"
                  id="email__phone"
                  type="number"
                  name="phone"
                  required
                />

                <label className="formLabel" for="email__subject">
                  Subject
                </label>
                <input
                  className="formInput"
                  id="email__subject"
                  type="text"
                  name="subject"
                  required
                />

                <label className="formLabel" for="email__message">
                  Message
                </label>
                <textarea
                  name="message"
                  id="email__message"
                  className="formInput"
                  rows="5"
                  required
                ></textarea>
                {/* <input id="email__message" type="text" name="message" /> */}
                <button
                  type="submit"
                  className="rounded-full bg-green-500 px-12 py-2 text-center text-white duration-300 hover:bg-green-800 md:pt-1.5 md:hover:scale-105"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-6 my-6 flex overflow-hidden rounded-md">
        <iframe
          className="h-96 w-full"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15835.529568572416!2d80.0938821!3d7.1395964!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2fdab05ee7b75%3A0x2cf2205ba2fe881c!2sGEO%20ECO(PVT)LTD!5e0!3m2!1sen!2slk!4v1719419861488!5m2!1sen!2slk"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </>
  );
};

export default Home;
